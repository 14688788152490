import React from 'react'

const Anti_Ragging = () => {
  return (
    <div>
      <h1 className='py-5 text-center'>Anti Ragging</h1>
    </div>
  )
}

export default Anti_Ragging
