import React from 'react'

const Syllabus = () => {
  return (
    <div>
      <h1 className='py-5 text-center'>Syllabus</h1>
    </div>
  )
}

export default Syllabus
