export const base_url = "https://colleage.vercel.app";
export const getAllBlogs = `${base_url + '/api/v1/blogs/getallblogs/?'}`;
export const getSingleBlog = `${base_url + '/api/v1/blogs/getSingleBlogs/'}`;
export const getAllImages = `${base_url + '/api/v1/gellery/getallimages?limit='}`;
export const getAllVideos = `${base_url + '/api/v1/video/getallVideo?limit=20'}`;
export const getAllPressImg = `${base_url + '/api/v1/pressrealse/getallpressrelase/'}`;
export const getAboutUs = `${base_url + '/api/v1/aboutus/getaboutus/'}`;
export const getAboutUsSingleData = `${base_url + '/api/v1/aboutus/getaboutus'}`;
export const getAcademicData = `${base_url + '/api/v1/acedamic/getacedamic/'}`;
export const getAcademicSingleData = `${base_url + '/api/v1/acedamic/getsingleacedamic'}`;
export const getAddmission = `${base_url + '/api/v1/addmission/getaddmission/'}`;
export const getSingleAddmission = `${base_url + '/api/v1/addmission/getsingleaddmission'}`;
export const getFacilities = `${base_url + '/api/v1/ficilities/getficilities/'}`;
export const getSingleFacility = `${base_url + '/api/v1/ficilities/getsingleficilities'}`;
export const getMedicalFacility = `${base_url + '/api/v1/medicalficilities/getallmedicalFicilities'}`;
export const getSingleMedicalFacility = `${base_url + '/api/v1/medicalficilities/getSinglemedicalFicilities'}`;
export const getDepartments = `${base_url + '/api/v1/department/getdepartment/'}`;
export const getSingleDepartment = `${base_url + '/api/v1/department/getsingledepartment'}`;
export const createContact = `${base_url}/api/v1/contact/uploadcontact`;
export const complainForm = `${base_url}/api/v1/complaint/uploadcomplaint`;
export const studentFeedbackForm = `${base_url}/api/v1/studentFeedback/uploadstudentFeedback/`;
export const alumniFeedbackForm = `${base_url}/api/v1/aluminFeedback/uploadalumniFeedback/`;
export const teacherFeedbackForm = `${base_url}/api/v1/teacherFeedback/uploadteacherFeedback/`;
export const parentsFeedbackForm = `${base_url}/api/v1/parentsFeedback/uploadparentsFeedback/`;
export const signup = `${base_url}/api/v1/addmission/uploadaddmission`;
export const login = `${base_url}/api/v1/addmission/studentlogin`;
export const entrenceExamForm = `${base_url}/api/v1/form/uploadform/`;
export const getAllCourses = `${base_url}/api/v1/course/getallcourses`;
export const getSingleCourses = `${base_url}/api/v1/course/getsinglecourse`;
export const getAllFeedback = `${base_url}/api/v1/studentFeedback/getallstudentFeedbacktrue/`;
export const getAllNews = `${base_url}/api/v1/news/getallnews/`;
export const getAllStudentPdf = `${base_url}/api/v1/student/getallpdf/`;
export const getSingleStudentPdf = `${base_url}/api/v1/student/getSinglepdf`;
export const getAllToppers = `${base_url}/api/v1/topper/getalltoppers/?limit=5`;
export const getallImageCategory = `${base_url}/api/v1/category/getcategory`;
export const getImageCategory = `${base_url}/api/v1/gellery/getImagebycategory`;



// post
