import React from 'react'

const Study_Materials = () => {
  return (
    <div>
      <h1 className='py-5 text-center'>Study Materials</h1>
    </div>
  )
}

export default Study_Materials
